import { ISelectValue } from "../../components/Select"

export const vehiclesInitialState:IVehiclesState = {
    agency:null,
    brand:null,
    model:null,
    transmissionType:null,
    fuelType:null,
    mileage:null,
    vehicleType:null,
    year:null,
    doors:null,
    seats:null,
    seasons:[{
        seasonId:'null',
        name:'Van sezone',
        rentPeriods:[]
    }],
    description:'',
    deposit:null,
    images:[]
}

export const vehiclesInvalidFields = Object.keys(vehiclesInitialState).filter(f=>!['seasons','images'].includes(f))

export interface IVehiclesState{
    agency:ISelectValue
    brand:ISelectValue
    model:ISelectValue
    transmissionType:ISelectValue
    fuelType:ISelectValue
    mileage:ISelectValue
    vehicleType:ISelectValue
    year:ISelectValue
    doors:ISelectValue
    seats:ISelectValue
    seasons:IVehicleStateSeason[]
    description:string
    deposit:number
    images:any[]
}

export interface IVehicleStateSeason{
    seasonId:number | string
    name:string
    rentPeriods:IVehicleSeasonRentPeriod[]
}

export interface IVehicleSeasonRentPeriod{
    id:number,
    price:number
    from:number
    to:number
    prevId?:number //sluzi za edit da se poveze vec kreirani rentPeriod sa sezonom
    index?:number
    
}


export const prices = [
    { label: 10, value: 10 },
    { label: 15, value: 15 },
    { label: 20, value: 20 },
    { label: 25, value: 25 },
    { label: 30, value: 30 },
    { label: 35, value: 35 },
    { label: 40, value: 40 },
    { label: 45, value: 45 },
    { label: 50, value: 50 },
    { label: 60, value: 60 },
    { label: 70, value: 70 },
    { label: 80, value: 80 },
    { label: 90, value: 90 },
    { label: 100, value: 100 },
    { label: 150, value: 150 },
    { label: 200, value: 200 },
    { label: 250, value: 250 },
    { label: 300, value: 300 },
    { label: 350, value: 350 },
    { label: 400, value: 400 },
    { label: 450, value: 450 },
    { label: 500, value: 500 },
    { label: 600, value: 600 },
    { label: 700, value: 700 },
    { label: 800, value: 800 },
    { label: 900, value: 900 },
    { label: 1000, value: 1000 },
  ];

  export const vehicleFilters:IVehicleFilters = {
      brand:null,
      agency:null,
      model:null,
      transmissionType:null,
      vehicleType:null,
      fuelType:null,
      from:null,
      to:null
  }

  export interface IVehicleFilters{
      brand:ISelectValue
      agency:ISelectValue
      model:ISelectValue
      transmissionType:ISelectValue
      vehicleType:ISelectValue
      fuelType:ISelectValue
      from:Date
      to:Date
  }


  export const parseFilters = (filters:IVehicleFilters) =>{
    return{
        agencyId:filters.agency?.value,
        brandId:filters.brand?.value,
        modelId:filters.model?.value,
        vehicleTypeId:filters.vehicleType?.value,
        transmissionTypeId:filters.transmissionType?.value,
        fuelTypeIds:[filters.fuelType?.value],
        from:filters.from,
        to:filters.to
    }

  }